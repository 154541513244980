.projects-container {
    padding: 60px 0 60px 0;
}

.projects-projects-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(600px, 600px));
    margin: 0 20px;
    justify-content: center;
    column-gap: 100px;
    padding: 30px 0 0 0;
}

.project-container {
    display: grid;
    grid-template-columns: 600px;
}

.project-project-title {
    font-weight: 600;
    font-size: 30px;
    text-align: center;
    padding: 0 0 10px 0;
}

.project-section-title {
    font-weight: 600;
    font-size: 20px;
    padding: 20px 0 2px 0;
}

.project-image {
    box-shadow: 10px 10px 5px grey;
    width: 100%;
    height: auto;
    border-radius: 5px;
}

.project-tech-stack-section {
    display: flex;
    flex-wrap: wrap;
}

.project-custom-skill {
    background-color: #f4511e;
    color: white;
    padding: 3px 6px;
    border-radius: 5px;
    margin: 0 5px 5px 0;
    font-size: 0.9rem;
}

@media screen and (max-width: 797px) {
    .projects-projects-container {
        display: grid;
        grid-template-columns: 1fr;
        margin: 0 20px;
        justify-content: center;
        padding: 30px 0 0 0;
        font-size: 0.9rem;
    }
    .project-container {
        display: grid;
        grid-template-columns: 1fr;
        padding: 0 0 30px 0;
    }
    .project-image {
        box-shadow: 10px 10px 5px grey;
        width: 100%;
        text-align: center;
    }
}
