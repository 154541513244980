.about-container {
    margin: 0 20px;
}

.about-object-text {
    text-align: center;
    padding: 100px 0 0 0;
}



.about-image-section {
    text-align: center;
    padding: 100px 0 0 0;
}

.about-image {
    max-width: 100%;
    border-radius: 50%;
    border: 2px solid #643D20;
    -webkit-box-shadow: 0 0 14px 0 rgba(100, 61, 32, 1);
    -moz-box-shadow: 0 0 14px 0 rgba(100, 61, 32, 1);
    box-shadow: 0 0 14px 0 rgba(100, 61, 32, 1);
}


.about-text-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, 1fr);
    grid-template-rows: auto;
    row-gap: 30px;
    text-align: center;
}

.about-text-row-1 {
    grid-column: 1 / span 6;
    grid-row: 1;
    text-align: center;
    padding: 30px 0 0 0;
}

.about-text-row-1-name {
    font-size: 24px;
    font-weight: bold;
    padding: 0 0 5px 0;
}


.about-degrees-section {
    grid-column: 2 / span 4;
    grid-row: 2;
}

.about-degrees {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    row-gap: 10px;
}

.about-spare-time-section {
    grid-column: 1 / span 6;
    grid-row: 3;
    padding: 10px 0 0 0;
}


.center-text {
    text-align: center;
}

@media screen and (max-width: 797px) {
    .about-wlu-section {
        grid-row: 2;
    }
    .about-osu-section {
        grid-row: 1;
    }
}

