.contact-container {
    padding: 60px 0 60px 0;
    text-align: center;
    background-color: whitesmoke;
}

.contact-form {
    padding: 30px 0 0 0;
    display: grid;
    grid-template-columns: 400px;
    row-gap: 20px;
    justify-content: center;
}

.contact-form-textfield {
    background-color: white;
    border-radius: 5px;
}

.contact-submit-button {
    background-color: blue;
}

@media screen and (max-width: 797px) {
    .contact-form {
        padding: 30px 0 0 0;
        display: grid;
        grid-template-columns: 80vw;
        row-gap: 20px;
        justify-content: center;
    }
}
