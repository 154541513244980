.footer-container {
    background: #424242;
    color: white;
    padding-top: 20px;
    padding-bottom: 10px;
    text-align: center;

    left: 0;
    bottom: 0;
    right: 0;
}