.experience-section {
    padding: 75px 0 0 0;
}

.experience-section-title {
    text-align: center;
    font-size: 45px;
    font-weight: 500;
}

.job-container {
    margin: 0 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
}


.job-left-column {
    padding: 20px 0 0 60%;
}

.job-company-title {
    font-weight: 700;
    font-size: 21px;
}

.job-time {
    padding: 5px 0 0 0;
}



.job-right-column {
    padding: 20px 0 0 8%;
}

.job-duty-list {
    margin: 5px 0;
}

.job-duty-list li {
    padding: 0 0 5px 0;
}

.job-link {
    text-decoration: none;
}


.job-container {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    padding: 20px 0;
}

.job-company-title-section {
    grid-column: 3 / span 2;
}

.job-job-title {
    font-size: 20px;
    font-weight: bold;
}

.job-details-section {
    grid-column: 5 / span 3;
}


.job-custom-skill-section {
    display: flex;
    flex-wrap: wrap;
}

.job-custom-skill {
    background-color: #f4511e;
    color: white;
    padding: 3px 6px;
    border-radius: 5px;
    margin: 0 5px 5px 0;
    font-size: 0.9rem;
}

@media screen and (max-width: 797px) {
    .job-container {
        margin: 0 20px;
        display: grid;
        grid-template-columns: 1fr;
        font-size: 0.9rem;
    }

    .job-company-title-section {
        grid-column: 1;
        grid-row: 1;
    }

    .job-details-section {
        grid-column: 1;
        grid-row: 2;
    }
}
