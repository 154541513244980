.education-container {
    margin: 50px 0 0 0;
    padding: 75px 0 100px 0;
    background: #424242;
    color: white;
}

.education-colleges {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    text-align: center;
}

.education-college-one {
    grid-column: 2 / span 2;
    padding: 50px 0 0 0;
}

.education-college-two {
    grid-column: 6 / span 2;
    padding: 50px 0 0 0;
}

.college-college-name {
    font-weight: 700;
    font-size: 21px;
}

.college-years-attended {
    font-size: 0.9rem;
}

.college-degree {
    font-weight: 700;
}

@media screen and (max-width: 797px) {
    .education-container {
        margin: 50px 0 0 0;
        padding: 75px 0 75px 0;
        background: #424242;
        color: white;
    }

    .education-colleges {
        display: grid;
        grid-template-columns: 1fr;
        margin: 0 20px;
    }

    .education-college-one {
        grid-column: 1;
        grid-row: 2;
        padding: 50px 0 0 0;
    }

    .education-college-two {
        grid-column: 1;
        grid-row: 1;
        padding: 50px 0 0 0;
    }
}
